<template>
	<div class="home">
		<!-- <div class="title">
		  <div>揭开命运的奥秘</div>
		  <div>迎接美好的未来!</div>
	  </div> -->
		<!-- 一 -->
		<div style="float: right;padding: 1rem;">
			
			
			<van-popover v-model:show="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
			  <template #reference>
			   <img style="width: 3rem;" src="../assets/yuyan.png" alt="" />
			  </template>
			</van-popover>
		</div>
		<div class="itemboxs" style="margin-top: 36rem;">
			
			<p class="itemtitle">
			    在這個充滿挑戰和機遇的時代，了解自己的命運軌跡，掌握人生的主動權，變得尤為重要。我們擁有多年的經驗和卓越的專業知識，致力於為您提供最準確、最詳細的命理分析，幫助您在人生的每個階段做出最佳決策。
			</p>
			<div class="boderbox">
			    <span class="bodertitle">我們的服務</span>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">八字命理解析: </span>
			        通過您的生辰八字，深度分析個人特質、財運、事業、婚姻等重要人生方面，幫助您找到最合適的發展方向。
			    </p>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">紫微斗數分析: </span>
			        解讀星象密碼，透析命盤中的秘密，提供個性化的人生建議，讓您在人生旅途中更為順利。
			    </p>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">面相與手相: </span>
			        提供居家和辦公室的風水佈局建議，優化環境能量流動，助您提升運勢，帶來好運。
			    </p>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">風水諮詢: </span>
			        從五官特徵和手掌紋路中探尋性格和命運，揭示您隱藏的潛力與未來的發展趨勢。
			    </p>
			</div>
			<div class="boderbox">
			    <span class="bodertitle">為什麼選擇我們?</span>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">專業團隊: </span>
			        我們的命理師均擁有多年實戰經驗，結合傳統智慧與現代科學，為您提供可信賴的分析和建議。
			    </p>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">個性化服務: </span>
			        根據您的個人需求，量身定制命理報告，幫助您解決生活中遇到的問題。
			    </p>
			    <p class="fs">
			        <span style="font-weight: 600;font-size: 1.1rem;">客戶滿意度: </span>
			        我們的服務得到了眾多客戶的好評和信任，我們致力
			        於提供優質的客戶體驗。
			    </p>
			</div>
			<div class="itemtitle" style="margin-top: 1.5rem;">聯繫我們</div>
			<div class="itemtitle">立即預約您的專屬命理諮詢，探索未來的無限可能!</div>
		</div>
		<!-- 二 -->
		<div class="itembox">
			
			<p class="itemtitle">
			    國學命理是中國傳統文化中的一部分，涉及到通過觀察和分析人的生辰八字（出生時間和日期）來預測其性格、命運和未來發展趨勢。命理學主要包括以下幾個方面：
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
			    <span class="bodertitle">八字命理</span>
			    <p class="fs" style="margin-bottom: 1rem;">
			        也稱為四柱命理，是中國傳統文化中一種古老的命理學，用於分析和預測個人的命運和性格。它基於一個人出生的時間和日期，通過陰陽五行和天干地支的組合，推算出一個人的命理格局。以下是對生辰八字的詳細介紹：
			    </p>
			    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">基本概念</div>
			    <div class="fs">1.八字和四柱:</div>
			    <p class="fs">
			        八字：指的是由四個天干和四個地支組成的八個字，即年柱、月柱、日柱和時柱。每個柱由一個天干和一個地支構成。
			    </p>
			    <p class="fs">
			        四柱：每個柱代表一個時間段，分別是年、月、日、時。
			    </p>
			    <div class="fs">2.天干地支:</div>
			    <p class="fs">
			        十天干：甲、乙、丙、丁、戊、己、庚、辛、壬、癸。
			    </p>
			    <p class="fs">
			        十二地支：子、丑、寅、卯、辰、巳、午、未、申、酉、戌、亥。
			    </p>
			    <p class="fs">
			        五行：金、木、水、火、土，每個天干和地支都有其對應的五行屬性。
			    </p>
			    <div class="fs">3.陰陽:</div>
			    <p class="fs">
			        天干和地支各自分為陰陽兩種屬性，天干中奇數為陽，偶數為陰；地支中子、寅、辰、午、申、戌為陽，丑、卯、巳、未、酉、亥為陰。
			    </p>
			    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">八字的計算</div>
			    <p class="fs" style="margin-bottom: 2rem;">
			        計算生辰八字需要知道一個人的出生日期和時間，然後根據中國農曆推算出對應的天干地支。
			    </p>
			    <p class="fs">
			        1.年柱：根據出生年的農曆天干地支。
			    </p>
			    <p class="fs">
			        2.月柱：根據出生月的節氣，結合年份的干支來推算。
			    </p>
			    <p class="fs">
			        3.日柱：根據出生日的天干地支計算。
			    </p>
			    <p class="fs">
			        4.時柱：根據出生時辰（24小時制分成十二個時辰）推算。
			    </p>
			    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">八字分析</div>
			    <p class="fs" style="margin-bottom: 2rem;">
			        分析八字需要了解五行生克關係、十神的作用和命理結構。
			    </p>
			    <div style="font-size: 1.1rem;margin-top: .8rem;">五行生克:</div>
			    <p class="fs">
			        1.相生：金生水，水生木，木生火，火生土，土生金。
			    </p>
			    <p class="fs">
			        2.相克：金克木，木克土，土克水，水克火，火克金。
			    </p>
			    <div class="baguagj">
			        <div style="font-size: 1.1rem;margin-top: .8rem;">十神:</div>
			        <p class="fs">
			            1.正印、偏印：代表精神、學識和長輩。
			        </p>
			        <p class="fs">
			            2.比肩、劫財：代表兄弟、朋友。
			        </p>
			        <p class="fs">
			            3.食神、傷官：代表個性、才能和子女。
			        </p>
			        <p class="fs">
			            4.正財、偏財：代表財富、物質和妻妾。
			        </p>
			        <p class="fs">
			            5.正官、七殺：代表事業、權力和丈夫。
			        </p>
			    </div>
			    <div style="font-size: 1.1rem;margin-top: 2rem;">命局強弱:</div>
			    <p class="fs">
			        1.通過分析五行的數量和相互作用，判斷命主的強弱，進而分析適合的發展方向和潛在的命運趨勢。
			    </p>
			</div>

			<div class="itemtitle" style="margin-top: 1.5rem;">實際應用</div>
			<p class="itemtitle">
			    1.性格分析：通過八字可以了解一個人的性格特點和行為模式。
			</p>
			<p class="itemtitle">
			    2.事業發展：根據命局中的官星和財星，可以預測事業的發展方向和財運狀況。
			</p>
			<p class="itemtitle">
			    3.婚姻感情：通過配偶星的位置和作用，分析婚姻關係的和諧程度。
			</p>
			<p class="itemtitle">
			    4.健康狀況：根據命理中五行的平衡與否，判斷健康上的優勢和隱患。
			</p>
			</div>
			<div class="newboder">
			    <p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			        注意事項
			    </p>
			    <p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			        生辰八字是一種傳統文化信仰，不同流派和師傅的解釋可能會有所不同。現代人應
			    </p>
			    <p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			        理性看待，將其作為自我反思和規劃的一種工具，而不是絕對的命運決定論。
			    </p>
			
		</div>

		<!-- 三 -->
		<div class="itembox" >
			<p class="itemtitle">
			    紫微斗數是中國傳統命理學中的一種，它利用星象來推測個人的命
			    運發展。紫微斗數被認為是一種高度系統化和精密的命理學體系，
			    它結合了星座、宮位、五行、陰陽等多種因素，對一個人的命運進
			    行全方位的分析。以下是對紫微斗數的詳細介紹:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
			    <span class="bodertitle">紫微斗數</span>

				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">紫微斗數的組成</div>
				<div style="font-size: 1.1rem;margin-top: .8rem;">1.命盤:</div>
				<p class="fs">
				    紫微斗數命盤由十二個宮位組成，分別代表人生的不同方面，包括命
				    宮、財帛宮、事業宮、夫妻宮、子女宮等。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">2.十四主星:</div>
				<p class="fs">
				    紫微斗數中的十四顆主星是紫微、天機、太陽、武曲、天同、廉貞、天
				    府、太陰、貪狼、巨門、天相、天梁、七殺、破軍。
				</p>
				<p class="fs">
				    每顆星都有其特定的性質和意義，例如紫微星代表領導力和權威，天機
				    星代表智慧和機遇。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: 2rem;">副星與雜曜:</div>
				<p class="fs">
				    1.除了十四主星，還有諸多副星（如文昌、文曲、左輔、右弼）和雜曜
				    （如火星、鈴星），這些星曜在命盤中的位置和組合會影響主星的力量
				    和意義。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">十二宮位:</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    命宮、兄弟宮、夫妻宮、子女宮、財帛宮、疾厄宮、遷移宮、仆役宮
				    事業宮、田宅宮、福德宮、父母宮。每個宮位都有其代表的人生領域。
				</p>
				
				<div class="fengshui">
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">紫微斗數的基礎理論</div>
					<div style="font-size: 1.1rem;margin-top: 2rem;">陰陽五行:</div>
					<p class="fs" style="margin-bottom: 2rem;">
					    星曜和宮位都受到陰陽五行的影響，通過分析五行的相生相克，可以洞
					    悟命主的性格和命運走向。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">四化:</div>
					<p class="fs">
					    四化指的是化祿、化權、化科、化忌四種變化，每個主星可以化為四化
					    之一，影響命主的運勢和行為。
					</p>
					<p class="fs">
					    1.化祿: 代表利益、好處、增益。
					</p>
					<p class="fs">
					    2.化權: 代表權力、力量、掌控。
					</p>
					<p class="fs">
					    3.化科: 代表名譽、地位、學術。
					</p>
					<p class="fs">
					    4.化忌: 代表阻礙、麻煩、失去。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">大限和流年:</div>
					<p class="fs">
					    1.大限: 每十年為一個大限，分析大限中的星曜組合可以了解十年的整
					    體運勢。
					</p>
					<p class="fs">
					    2.流年: 每一年為一個流年，流年宮位的變化影響該年的具體運勢。
					</p>
				</div>
				
				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">紫微斗數的應用</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1.性格分析</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    通過命盤中主星、副星的位置和組合，分析命主的性格特質。例如，命
				    宮有紫微星的人通常具有領導才能，個性堅毅;命宮有天機星的人通常
				    聰明機智，善於隨機應變。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">2.事業發展</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    分析事業宮中的星曜及其四化情況，可以預測命主在職業生涯中的發展
				    趨勢和可能面臨的挑戰。例如，事業宮有武曲星的人通常在金融、管理
				    等領域有出色表現。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">3.財運狀況</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    財帛宮的星曜組合和化氣關係可以揭示命主的財富來源和財務狀況。化
				    禄在財帛宮通常預示著財運較好，而化忌則可能預示財務困難。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">4.婚姻感情</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    夫妻宮中的星曜可以揭示婚姻生活的狀況和配偶的特質。例如，夫妻宮
				    有太陰星的人可能會遇到一個溫柔體貼的伴侶。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">5.健康狀態</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    疾厄宮的星曜和五行關係可以反映出命主的健康狀況，指出身體上的優勢和潛在的疾病隱患。
				</p>
							
					
				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">紫微斗數的流派</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    紫微斗數有不同的流派，如南派、北派等，各流派在具體分析和推演方
				    法上可能會有所不同，但總體上都基於相同的理論體系。
				</p>
				<div class="itemtitle" style="margin-top: 1.5rem;">注意事項</div>
				<p class="itemtitle">
				    紫微斗數作為一種傳統文化信仰，具有高度的象徵性和解讀性。
				    它強調命運的多樣性和可能性，現代人在使用紫微斗數進行自我了解時，應結合個人實際情況和現代科學知識，保持理性和客觀的態度。
				</p>
				<p class="itemtitle" style="margin-top: 1.5rem;">
				    風水是中國傳統文化中的一項重要學問，它通過研究環境與人之間
				    的關係，探討如何調整和優化居住環境，以達到趨吉避兇、增運添
				    福的目的。風水不僅僅是一種建築學和環境學，也是對自然規律的
				    尊重和應用。
				</p>
				
			</div>
		</div>
		
		<!-- 四 -->
		<div class="itembox" >
			
			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle">風水</span>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">1.陰陽與五行</div>
				<p class="fs">
				    陰陽: 風水中的陰陽概念是指自然界的對立統一，例如光明與黑暗、動
				    與靜、熱與冷等。陰陽調和是風水佈局的基本原則。
				</p>
				<p class="fs">
				    五行: 金、木、水、火、土五種基本元素構成萬物，並通過相生相克關
				    係影響環境和運勢。五行相生: 金生水，水生木，木生火，火生土，土
				    生金; 五行相克: 金克木，木克土，土克水，水克火，火克金。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">2.方位與八卦</div>
				<p class="fs">
				    方位: 風水學強調房屋、墓地的坐向和方位對人的影響。八個方位與五
				    行、八卦相結合，構成了風水學的重要理論基礎。
				</p>
				<p class="fs">
				    八卦: 乾、兌、離、震、巽、坎、艮、坤八個卦象分別代表不同的方位
				    和屬性，八卦圖用於分析和預測環境的風水狀況。
				</p>
		
				
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">風水的流派</div>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">1.形勢派</div>
					<p class="fs">
					    形勢派注重地形地貌，講究山水的形態和流向。形勢派認為地理環境的自
					    然形勢（如山川、河流）對風水的影響至關重要。
					</p>
					<p class="fs">
					    峦頭: 指地形的起伏、高低、曲直等，良好的峦頭能聚氣生旺，反之則
					    可能導致不利。
					</p>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">2.理氣派</div>
					<p class="fs">
					    理氣派強調方位、時間、氣流的動態變化，通過羅盤定位和時辰來進行
					    風水佈局。
					</p>
					<p class="fs">
					    飛星: 是理氣派的重要方法之一，根據時間的變化（如年份、月份、日
					    子）來調整風水佈局。
					</p>
					
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">風水的應用</div>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">1.居家風水</div>
					<p class="fs" style="margin-bottom: 2rem;">
					    選址與坐向: 房屋的選址與坐向應考慮周圍環境的形勢，如是否有靠
					    山、水流等。
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
					    室內佈局: 風水中強調房屋內部的佈局，如臥室、廚房、客廳的方位門窗的位置等，力求達到氣流順暢、光線充足、五行平衡。
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
					    家具擺放: 家具的擺放需要符合風水原則，例如床頭不宜朝向門，書桌應避免背門而坐。
					</p>
				
				<div style="font-size: 1.1rem;margin-top: 2rem;">2. 辦公風水</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    辦公室位置：選擇合適的辦公室位置和樓層，可以提高工作效率和事業運。
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
				    辦公桌布局：辦公桌的擺放和朝向需要考慮主人的命卦和方位，以增強工作運勢。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: 2rem;">3. 陽宅與陰宅</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    陽宅：指活人居住的房屋，陽宅風水直接影響居住者的健康、財運和人際關係。
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
				    陰宅：指墓地和墳墓，陰宅風水被認為影響後代的運勢，因此選址和布局尤為重要。
				</p>
				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">風水的原則</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1. 藏風聚氣</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    風水講究「藏風聚氣」，即通過合理的環境設計來保持氣場的穩定和流暢，使居住環境充滿生氣。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">2. 天人合一</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    風水強調人與自然的和諧共處，通過調整環境使其符合自然規律，從而改善生活質量和運勢。
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">風水的現代應用</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    在現代建築和室內設計中，風水常被用來改善環境舒適度，提升空間的正能量。通過合理的設計，風水可以幫助人們獲得更好的健康、財富和幸福感。
				</p>
				
				<div class="itemtitle" style="margin-top: 1.5rem;">注意事項</div>
				<p class="itemtitle">
				    風水學具有文化和信仰性質，現代人在應用風水時，應結合科學知識和個人實際情況，理性看待風水的作用，不應過度依賴。
				</p>
				
			</div>
		</div>
		
		<!-- 五 -->
		<div class="itembox" >
			<p class="itemtitle">
			    面相和手相是中國傳統命理學中兩種重要的方法，用於分析和預測個人的性格、命運和運勢。
			    這些方法通過觀察面部特徵和手掌紋路，提供關於一個人性格和命運的信息。以下是對面相和手相的詳細介紹：
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
			    <span class="bodertitle">面相與手相</span>
		
				<div style="font-size: 1.1rem;margin-top: 2rem;">眉毛（保壽官）</div>
				<p class="fs">
				    1. 形態：眉毛清秀、順直者一般性格溫和，意志堅定；雜亂或斷續者可能情緒不穩，生活中多波折。
				</p>
				<p class="fs">
				    2. 長短：眉毛長過眼角，意味著壽命長，兄弟情深。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">眼睛（監察官）</div>
				<p class="fs">
				    1. 大小：眼睛大者通常心胸寬廣，感情豐富；小眼者可能心思細膩，思慮周全。
				</p>
				<p class="fs">
				    2. 形狀：圓眼表示熱情，杏眼代表聰慧，細長眼則象徵理智和冷靜。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">鼻子（財帛官）</div>
				<p class="fs">
				    1. 高低：鼻樑高挺者一般自信、有主見；鼻子低平者可能缺乏自信。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">嘴巴(出納官):</div>
				<p class="fs">
				    1.大小:嘴大者多具包容力和社交能力，嘴小者較為保守。
				</p>
				<p class="fs">
				    2.形狀:唇厚者感情豐富，唇薄者理性自制。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">耳朵(采聽官):</div>
				<p class="fs">
				    1.厚薄:耳厚者身體健康、長壽，耳薄者身體較弱。
				</p>
				<p class="fs">
				    2.位置:耳高於眉，聰明好學，耳低者性情溫和。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">面部區域</div>
				<p class="fs">
				    面相學將面部分為多個區域，每個區域對應人生的不同階段和運勢。
				</p>
				<p class="fs">
				    1.額頭:代表早年運勢，額頭寬闊、光潔者早年生活順利，事業起步順暢。
				</p>
				<p class="fs">
				    2.顴骨:象徵權力與影響力，顴骨高者多具領導才能。
				</p>
				<p class="fs">
				    3.下巴:與晚年運勢相關，下巴圓潤者晚年幸福，生活安逸。
				</p>
				
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">氣色觀察</div>
					
					<p class="fs">
					    面部的氣色也被認為是一個人運勢的反映。氣色紅潤者多具活力，運勢順暢；面色黯淡者可能近期運勢較低，需多加注意。
					</p>
					
					<span class="bodertitle" style="margin-top: 2.5rem;">手相學</span>
					
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">手掌紋路</div>
					
					<p class="fs">
					    手相學中的三大主紋是生命線、智慧線和感情線。除此之外，還有婚姻線、事業線等細線。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">生命線:</div>
					<p class="fs">
					    1.位置:位於拇指與食指之間，向掌底彎曲。
					</p>
					<p class="fs">
					    2.意義:生命線長者體質好、精力旺盛；短者可能體質較弱。紋路深者健康狀況佳，淺者精力不濟。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">智慧線:</div>
					<p class="fs">
					    1.位置:起於食指與中指之間，橫跨掌心。
					</p>
					<p class="fs">
					    2.意義:智慧線長而清晰者思維敏捷，短者思路直接，簡單明瞭。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">感情線:</div>
					<p class="fs">
					    1.位置:起於小指側，向食指方向延伸。
					</p>
					<p class="fs">
					    2.意義:感情線深長者感情豐富，情感細膩；短者感情較淡薄。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">手指形狀</div>
					<p class="fs">
					    1.拇指:拇指長者通常意志堅定，具有領導才能。
					</p>
					<p class="fs">
					    2.食指:食指長者多有自信心和掌控慾。
					</p>
					<p class="fs">
					    3.中指:中指長者多具耐性和責任感。
					</p>
					<p class="fs">
					    4.無名指:無名指長者多具藝術才華。
					</p>
					<p class="fs">
					    5.小指:小指長者善於溝通，社交能力強。
					</p>
				
				<div class="shouxiang">
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">手掌特徵</div>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">手掌厚薄:</div>
					<p class="fs" style="margin-bottom: 2rem;">
					    1.厚實: 通常代表身體健康，做事踏實。
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
					    2.薄弱: 可能體質較弱，性格敏感。
					</p>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">手掌大小:</div>
					<p class="fs" style="margin-bottom: 2rem;">
					    1.大掌: 性格外向，注重行動。
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
					    2.小掌: 思維細膩，注重思考。
					</p>
				</div>
				
			
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">手相應用</div>
					
					<p class="fs" style="margin-bottom: 2rem;">
					    手相常用於分析個人的性格特點、健康狀況以及事業發展。不同的手相特徵可以提示人們在特定階段可能遇到的挑戰和機遇。
					</p>
					
					<div class="itemtitle" style="margin-top: 1.5rem;">注意事項</div>
					<p class="itemtitle">
					    面相和手相學作為傳統文化的一部分，帶有一定的象徵性和解讀性。在現代應用中，應結合個人實際情況和科學知識，理性看待其作用，不應過於依賴。
					</p>
				
			</div>
			
		</div>
		
		<div class="newboders">
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			    結語
			</p>
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			    國學命理學以豐富的哲學體系和歷史積澱為基礎，強調人與自然、宇宙的和諧關係。它既是一種文化傳承，也是一種哲學思考方式，在現代社會中仍然有廣泛的影響力和研究價值。
			    需要注意的是，命理學是一種傳統文化信仰，不同的人可能對其有不同的看法，具體應用時需結合科學理性進行分析。
			</p>
			
		</div>

	</div>
</template>

<script setup>

	import { ref } from 'vue';
	import { showToast } from 'vant';
	import {useRouter} from 'vue-router';
	const router = useRouter();
	const showPopover = ref(false);
	
	    // 通过 actions 属性来定义菜单选项
	    const actions = [
	      { text: 'English' },
	      { text: '中文简体' },
	      { text: '中文繁體' },
	    ];
	    const onSelect = (action) => {
			console.log(action.text);
			if(action.text == 'English') {
				router.push('/Homeen')
			}
			if(action.text == '中文简体') {
				router.push('/')
			}
			if(action.text == '中文繁體') {
				router.push('/Homezh')
			}
		};
</script>
<style lang="less" scoped>
	.fs {
		font-size: 1.1rem;
		line-height: 1.6;
		margin-top: 1rem;
	}

	.home {
		box-sizing: border-box;
		// min-height: 100vh;
		width: 100%;
		min-height: 150vh;
		background: url(../assets/homebj.png) no-repeat;
		background-size: cover;
		overflow-y: scroll;

		.title {
			text-align: center;
			font-family: '楷体';
			font-weight: 600;
			font-size: 1.5rem;
		}
			
		.fengshui {
			background: url(../assets/fengshui.png) no-repeat;
			background-size: 18rem 17rem;
			background-position: top right;
		}
		.shanshui {

			background: url(../assets/sshui.png) no-repeat;
			background-size: 100% ;
			background-position: center 3rem;
		}
          
		.shouxiang {
			background: url(../assets/shouxiang.png) no-repeat;
			background-size: 100% ;
			background-position: center 3rem;
		}
			
		.shouxiang {
			background: url(../assets/shouxiang.png) no-repeat;
			background-size: 70% 100%;
			background-position: top right;
		}
		
		.itembox,
		.itemboxs {
			margin-top: 2rem;
			// width: 100%;
			min-height: 40rem;
			background: url(../assets/newitembj.png) no-repeat;
			background-size: 100% 100%;
			// background-position: center center;
			background-position: .25rem 0rem;

			padding: 3rem 4rem;
			padding-bottom: 6rem;

			.itemtitle {
				margin: 0 auto;
				color: #af874b;
				font-size: 1.1rem;
				line-height: 1.6;
			}

			.boderbox {
				margin-top: 2rem;
				min-height: 20rem;
				border: 1px solid #e4d9ce;
				padding: 1rem;

				.bodertitle {
					display: inline-block;
					background: url(../assets/tixtbj.png) no-repeat;
					background-size: 100% 100%;
					padding: .5rem 1.5rem;
					color: #fff;
				}

				.baguabj {
					background: url(../assets/bgbg.png) no-repeat;
					background-size: 13rem 12rem;
					background-position: bottom right;
				}
			}
		}

		.itemboxs {
			background: url(../assets/itembj.png) no-repeat !important;
			background-size: 100% 100% !important;
			 
			
			
		}

		.newboder {
			width: 90%;
			min-height: 10rem;
			margin-left: 5%;
			background: url(../assets/newboder.png) no-repeat !important;
			background-size: 100% 100% !important;
			padding: 1.5rem 2rem;
			box-sizing: border-box;

		}
		.newboders {
			width: 90%;
			min-height: 10rem;
			margin-left: 5%;
			background: url(../assets/botttom.png) no-repeat !important;
			background-size: 100% 100% !important;
			padding: 1.5rem 2rem;
			box-sizing: border-box;
			margin-bottom: 9rem;
			margin-top: 3rem;
		
		}
	}
</style>