<template>
	<div class="home">
		<!-- <div class="title">
		  <div>揭开命运的奥秘</div>
		  <div>迎接美好的未来!</div>
	  </div> -->
		<!-- 一 -->
		<div style="float: right;padding: 1rem;">
			
			
			<van-popover v-model:show="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
			  <template #reference>
			   <img style="width: 3rem;" src="../assets/yuyan.png" alt="" />
			  </template>
			</van-popover>
		</div>
		<div class="itemboxs" style="margin-top: 39rem;">
			<p class="itemtitle">
				在这个充满挑战和机遇的时代，了解自己的命运轨迹，掌握人生的
				主动权，变得尤为重要。我们拥有多年的经验和卓越的专业知识，
				致力于为您提供最准确、最详细的命理分析，帮助您在人生的每个
				阶段做出最佳决策。
			</p>
			<div class="boderbox">
				<span class="bodertitle">我们的服务</span>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">八字命理解析: </span>
					通过您的生辰八字，深度分析个人特质、财运、事业、婚姻等重要人生方面，帮助您找到最合适的发展方向。
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">紫微斗数分析: </span>
					解读星象密码，透析命盘中的秘密，提供个性化的人生建议，让您在人生旅途中更为顺利。
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">面相与手相: </span>
					提供居家和办公室的风水布局建议，优化环境能量流动，助您提升运势，带来好运。
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">风水咨询: </span>
					从五官特征和手掌纹路中探寻性格和命运，揭示您隐藏的潜力与未来的发展趋势。
				</p>
			</div>
			<div class="boderbox">
				<span class="bodertitle">为什么选择我们?</span>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">专业团队: </span>
					我们的命理师均拥有多年实战经验，结合传统智慧与现代科学，为您提供可信赖的分析和建议。
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">个性化服务: </span>
					根据您的个人需求，量身定制命理报告，帮助您解决生活中遇到的问题。
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">客户满意度: </span>
					我们的服务得到了众多客户的好评和信任，我们致力
					于提供优质的客户体验。
				</p>
			</div>
			<div class="itemtitle" style="margin-top: 1.5rem;">联系我们</div>
			<div class="itemtitle">立即预约您的专属命理咨询，探索未来的无限可能!</div>
		</div>
		<!-- 二 -->
		<div class="itembox">
			<p class="itemtitle">
				国学命理是中国传统文化中的一部分，涉及到通过观察和分析人的
				生辰八字(出生时间和日期)来预测其性格、命运和未来发展趋
				势。命理学主要包括以下几个方面:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle">八字命理</span>
				<p class="fs" style="margin-bottom: 1rem;">
					也称为四柱命理，是中国传统文化中一种古老的命理学，用于分析和预
					测个人的命运和性格。它基于一个人出生的时间和日期，通过阴阳五行
					和天干地支的组合，推算出一个人的命理格局。以下是对生辰八字的详
					细介绍:
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">基本概念</div>
				<div class="fs">1.八字和四柱:</div>
				<p class="fs">
					八字:指的是由四个天干和四个地支组成的八个字，即年柱、月柱、日
					柱和时柱。每个柱由一个天干和一个地支构成。
				</p>
				<p class="fs">
					四柱:每个柱代表一个时间段，分别是年、月、日、时。
				</p>
				<div class="fs">2.天干地支:</div>
				<p class="fs">
					十天干:甲、乙、丙、丁、戊、己、庚、辛、壬、癸。
				</p>
				<p class="fs">
					十二地支:子、丑、寅、卯、辰、巳、午、未、申、酉、成、亥。
				</p>
				<p class="fs">
					五行:金、木、水、火、土，每个天干和地支都有其对应的五行属性。
				</p>
				<div class="fs">3.阴阳:</div>
				<p class="fs">
					天干和地支各自分为阴阳两种属性，天干中奇数为阳，偶数为阴;地支
					中子、寅、辰、午、申、成为阳，丑、卯、巳、未、酉、亥为阴。
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">八字的计算</div>
				<p class="fs" style="margin-bottom: 2rem;">
					计算生辰八字需要知道一个人的出生日期和时间，然后根据中国农历推
					算出对应的天干地支。
				</p>
				<p class="fs">
					1.年柱:根据出生年的农历天干地支。
				</p>
				<p class="fs">
					2.月柱:根据出生月的节气，结合年份的干支来推算。
				</p>
				<p class="fs">
					3.日柱:根据出生日的天干地支计算。
				</p>
				<p class="fs">
					4.时柱:根据出生时辰(24小时制分成十二个时辰)推算。
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">八字分析</div>
				<p class="fs" style="margin-bottom: 2rem;">
					分析八字需要了解五行生克关系、十神的作用和命理结构。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">五行生克:</div>
				<p class="fs">
					1.相生:金生水，水生木，木生火，火生土，土生金。
				</p>
				<p class="fs">
					2.相克:金克木，木克土，土克水，水克火，火克金。
				</p>
				<div class="baguabj">
					<div style="font-size: 1.1rem;margin-top: .8rem;">十神:</div>
					<p class="fs">
						1.正印、偏印:代表精神、学识和长辈。
					</p>
					<p class="fs">
						2.比肩、劫财:代表兄弟、朋友。
					</p>
					<p class="fs">
						3.食神、伤官:代表个性、才能和子女。
					</p>
					<p class="fs">
						4.正财、偏财:代表财富、物质和妻妾。
					</p>
					<p class="fs">
						5.正官、七杀:代表事业、权力和丈夫。
					</p>
				</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">命局强弱:</div>
				<p class="fs">
					1.通过分析五行的数量和相互作用，判断命主的强弱，进而分析适合的
					发展方向和潜在的命运趋势。
				</p>
			</div>

			<div class="itemtitle" style="margin-top: 1.5rem;">实际应用</div>
			<p class="itemtitle">
				1.性格分析:通过八字可以了解一个人的性格特点和行为模式。
			</p>
			<p class="itemtitle">
				2.事业发展:根据命局中的官星和财星，可以预测事业的发展方向和财运状况。
			</p>
			<p class="itemtitle">
				3.婚姻感情:通过配偶星的位置和作用，分析婚姻关系的和谐程度。
			</p>
			<p class="itemtitle">
				4.健康状况:根据命理中五行的平衡与否，判断健康上的优势和隐患。
			</p>
		</div>
		<div class="newboder">
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				注意事项
			</p>
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				生辰八字是一种传统文化信仰，不同流派和师傅的解释可能会有所不同。现代人应
			</p>
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				理性看待，将其作为自我反思和规划的一种工具，而不是绝对的命运决定论。
			</p>
		</div>

		<!-- 三 -->
		<div class="itembox" >
			<p class="itemtitle">
				紫微斗数是中国传统命理学中的一种，它利用星象来推测个人的命
				运发展。紫微斗数被认为是一种高度系统化和精密的命理学体系
				它结合了星座、宫位、五行、阴阳等多种因素，对一个人的命运进
				行全方位的分析。以下是对紫微斗数的详细介绍:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle">紫微斗数</span>

				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">紫微斗数的组成</div>
				<div style="font-size: 1.1rem;margin-top: .8rem;">1.命盘:</div>
				<p class="fs">
					紫微斗数命盘由十二个宫位组成，分别代表人生的不同方面，包括命
					宫、财帛宫、事业宫、夫妻宫、子女宫等。
				</p>

				<div style="font-size: 1.1rem;margin-top: .8rem;">2.十四主星:</div>
				<p class="fs">
					紫微斗数中的十四颗主星是紫微、天机、太阳、武曲、天同、廉贞、天
					府、太阴、贪狼、巨门、天相、天梁、七杀、破军。
				</p>
				<p class="fs">
					每颗星都有其特定的性质和意义，例如紫微星代表领导力和权威，天机
					星代表智慧和机遇。
				</p>

				<div style="font-size: 1.1rem;margin-top: 2rem;">副星与杂曜:</div>
				<p class="fs">
					1.除了十四主星，还有诸多副星(如文昌、文曲、左辅、右弼)和杂曜
					(如火星、铃星)，这些星曜在命盘中的位置和组合会影响主星的力量
					和意义。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">十二宫位:</div>
				<p class="fs" style="margin-bottom: 2rem;">
					命宫、兄弟宫、夫妻宫、子女宫、财帛宫、疾厄宫、迁移宫、仆役宫
					事业宫、田宅宫、福德宫、父母宫。每个宫位都有其代表的人生领域。
				</p>
				
				<div class="fengshui">
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">紫微斗数的基础理论</div>
					<div style="font-size: 1.1rem;margin-top: 2rem;">阴阳五行:</div>
					<p class="fs" style="margin-bottom: 2rem;">
						星曜和宫位都受到阴阳五行的影响，通过分析五行的相生相克，可以洞
						悉命主的性格和命运走向。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">四化:</div>
					<p class="fs">
						四化指的是化禄、化权、化科、化忌四种变化，每个主星可以化为四化
						之一，影响命主的运势和行为。
					</p>
								
						<p class="fs">
							1.化禄:代表利益、好处、增益,
						</p>
						<p class="fs">
							2.化权:代表权力、力量、掌控。
						</p>
						<p class="fs">
							3.化科:代表名誉、地位、学术。
						</p>
						<p class="fs">
							4.化忌:代表阻碍、麻烦、失去。
						</p>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">大限和流年:</div>
					<p class="fs">
						1.大限:每十年为一个大限，分析大限中的星曜组合可以了解十年的整
						体运势。2.流年:每一年为一个流年，流年宫位的变化影响该年的具体
						运势。
					</p>
				</div>
				
				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">紫微斗数的应用</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1.性格分析</div>
				<p class="fs" style="margin-bottom: 2rem;">
					通过命盘中主星、副星的位置和组合，分析命主的性格特质。例如，命
					宫有紫微星的人通常具有领导才能，个性坚毅;命宫有天机星的人通常
					聪明机智，善于随机应变。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">2.事业发展</div>
				<p class="fs" style="margin-bottom: 2rem;">
					分析事业宫中的星曜及其四化情况，可以预测命主在职业生涯中的发展
					趋势和可能面临的挑战。例如，事业宫有武曲星的人通常在金融、管理
					等领域有出色表现。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">3.财运状况</div>
				<p class="fs" style="margin-bottom: 2rem;">
					财帛宫的星曜组合和化气关系可以揭示命主的财富来源和财务状况。化
					禄在财帛宫通常预示着财运较好，而化忌则可能预示财务困难。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">4.婚姻感情</div>
				<p class="fs" style="margin-bottom: 2rem;">
					夫妻宫中的星曜可以揭示婚姻生活的状况和配偶的特质。例如，夫妻宫
					有太阴星的人可能会遇到一个温柔体贴的伴侣。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">5.健康状态</div>
				<p class="fs" style="margin-bottom: 2rem;">
					疾厄宫的星曜和五行关系可以反映出命主的健康状况，指出身体上的优势和潜在的疾病隐患。
				</p>
							
					
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">紫微斗数的流派</div>
				<p class="fs" style="margin-bottom: 2rem;">
					紫微斗数有不同的流派，如南派、北派等，各流派在具体分析和推演方
					法上可能会有所不同，但总体上都基于相同的理论体系。
				</p>
				
				
				<div class="itemtitle" style="margin-top: 1.5rem;">注意事项</div>
				<p class="itemtitle">
					紫微斗数作为一种传统文化信仰，具有高度的象征性和解读性。
					它强调命运的多样性和可能性，现代人在使用紫微斗数进行自我了解时，应结合个人实际情况和现代科学知识，保持理性和客观的态度。
				</p>
				
				<p class="itemtitle" style="margin-top: 1.5rem;">
					风水是中国传统文化中的一项重要学问，它通过研究环境与人之间
					的关系，探讨如何调整和优化居住环境，以达到趋吉避凶、增运添
					福的目的。风水不仅仅是一种建筑学和环境学，也是对自然规律的
					尊重和应用。
				</p>
				
			</div>
		</div>
		
		<!-- 四 -->
		<div class="itembox" >
			
			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle">风水</span>
		
				<div style="font-size: 1.1rem;margin-top: .8rem;">1.阴阳与五行</div>
				<p class="fs">
					阴阳: 风水中的阴阳概念是指自然界的对立统一，例如光明与黑暗、动
					与静、热与冷等。阴阳调和是风水布局的基本原则。
				</p>
				<p class="fs">
					五行:金、木、水、火、土五种基本元素构成万物，并通过相生相克关
					系影响环境和运势。五行相生:金生水，水生木，木生火，火生土，士
					生金;五行相克:金克木，木克土，土克水，水克火，火克金。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">2.方位与八卦</div>
				<p class="fs">
					方位:风水学强调房屋、墓地的坐向和方位对人的影响。八个方位与五
					行、八卦相结合，构成了风水学的重要理论基础。
				</p>
				<p class="fs">
					八卦:乾、兑、离、震、巽、坎、艮、坤八个卦象分别代表不同的方位
					和属性，八卦图用于分析和预测环境的风水状况。
				</p>
		
				
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">风水的流派</div>
					<div style="font-size: 1.1rem;margin-top: 2rem;">1.形势派形</div>
					<p class="fs" >
						势派注重地形地貌，讲究山水的形态和流向。形势派认为地理环境的自
						然形势(如山川、河流)对风水的影响至关重要。
					</p>
					<p class="fs" >
						峦头:指地形的起伏、高低、曲直等，良好的峦头能聚气生旺，反之则
						可能导致不利。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">2.理气派</div>
					<p class="fs">
						理气派强调方位、时间、气流的动态变化，通过罗盘定位和时辰来进行
						风水布局。
					</p>
					<p class="fs">
						飞星:是理气派的重要方法之一，根据时间的变化(如年份、月份、日
						子)来调整风水布局。
					</p>
				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">风水的应用</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1.居家风水</div>
				<p class="fs" style="margin-bottom: 2rem;">
					选址与坐向:房屋的选址与坐向应考虑周围环境的形势，如是否有靠
					山、水流等。
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
					室内布局:风水中强调房屋内部的布局，如卧室、厨房、客厅的方位门窗的位置等，力求达到气流顺畅、光线充足、五行平衡。
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
					家具摆放:家具的摆放需要符合风水原则，例如床头不宜朝向门，书桌应避免背门而坐。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: 2rem;">2.办公风水</div>
				<p class="fs" style="margin-bottom: 2rem;">
					办公室位置:选择合适的办公室位置和楼层，可以提高工作效率和事业运。
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
					办公桌布局:办公桌的摆放和朝向需要考虑主人的命卦和方位，以增强工作运势。
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">3.阳宅与阴宅</div>
				<p class="fs" style="margin-bottom: 2rem;">
					阳宅:指活人居住的房屋，阳宅风水直接影响居住者的健康、财运和人际关系。
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
					阴宅:指墓地和坟墓，阴宅风水被认为影响后代的运势，因此选址和布局尤为重要。
				</p>
			
				<div class="shanshui">
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">风水的原则</div>
					<div style="font-size: 1.1rem;margin-top: 2rem;">1.藏风聚气</div>
					<p class="fs" style="margin-bottom: 2rem;">
						风水讲究“藏风聚气”，即通过合理的环境设计来保持气场的稳定和流畅，使居住环境充满生气。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">2.天人合一</div>
					<p class="fs" style="margin-bottom: 2rem;">
						风水强调人与自然的和谐共处，通过调整环境使其符合自然规律，从而改善生活质量和运势。
					</p>
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">风水的现代应用</div>
					<p class="fs" style="margin-bottom: 2rem;">
						在现代建筑和室内设计中，风水常被用来改善环境舒适度，提升空间的正能量。通过合理的设计，风水可以帮助人们获得更好的健康、财富和幸福感。
					</p>
				</div>
				
				
				<div class="itemtitle" style="margin-top: 1.5rem;">注意事项</div>
				<p class="itemtitle">
					风水学具有文化和信仰性质，现代人在应用风水时，应结合科学知识和个人实际情况，理性看待风水的作用，不应过度依赖。
				</p>
				
			</div>
		</div>
		
		<!-- 五 -->
		<div class="itembox" >
			<p class="itemtitle">
				面相和手相是中国传统命理学中两种重要的方法，用于分析和预测个人的性格、命运和运势。
				这些方法通过观察面部特征和手掌纹路，提供关于一个人性格和命运的信息。以下是对面相和手相的详细介绍:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle" >面相与手相</span>
		
				<div style="font-size: 1.1rem;margin-top: 2rem;">眉毛(保寿官):</div>
				<p class="fs">
					1.形态:眉毛清秀、顺直者一般性格温和，意志坚定;杂乱或断续者可能情绪不稳，生活中多波折。
				</p>
				<p class="fs">
					2.长短:眉毛长过眼角，意味着寿命长，兄弟情深。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">眼睛(监察官):</div>
				<p class="fs">
					1.大小:眼睛大者通常心胸宽广，感情丰富;小眼者可能心思细腻，思虑周全。
				</p>
				<p class="fs">
					2.形状:圆眼表示热情，杏眼代表聪慧，细长眼则象征理智和冷静。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">鼻子(财帛官):</div>
				<p class="fs">
					1.高低:鼻梁高挺者一般自信、有主见，低平者可能缺乏自信。
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">嘴巴(出纳官):</div>
				<p class="fs">
					1.大小:嘴大者多具包容力和社交能力，嘴小者较为保守。
				</p>
				<p class="fs">
					2.形状:唇厚者感情丰富，唇薄者理性自制。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">耳朵(采听官):</div>
				<p class="fs">
					1.厚薄:耳厚者身体健康、长寿，耳薄者身体较弱。
				</p>
				<p class="fs">
					2.位置:耳高于眉，聪明好学，耳低者性情温和。
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">面部区域</div>
				<p class="fs">
					面相学将面部分为多个区域，每个区域对应人生的不同阶段和运势。
				</p>
				<p class="fs">
					1.额头:代表早年运势，额头宽阔、光洁者早年生活顺利，事业起步顺
					畅。
				</p>
				<p class="fs">
					2.颧骨:象征权力与影响力，颧骨高者多具领导才能。
				</p>
				<p class="fs">
					3.下巴:与晚年运势相关，下巴圆润者晚年幸福，生活安逸。
				</p>
		
				
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">气色观察</div>
			
					<p class="fs" >
					面部的气色也被认为是一个人运势的反映。气色红润者多具活力，运势顺畅;面色黯淡者可能近期运势较低，需多加注意。
					</p>
					
					<span class="bodertitle" style="margin-top: 2.5rem;">手相学</span>
					
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">手掌纹路</div>
			
					<p class="fs">
						手相学中的三大主纹是生命线、智慧线和感情线。除此之外，还有婚姻线、事业线等细线。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">生命线:</div>
					<p class="fs">
						1.位置:位于拇指与食指之间，向掌底弯曲。
					</p>
					<p class="fs">
						2.意义:生命线长者体质好、精力旺盛;短者可能体质较弱。纹路深者健康状况佳，浅者精力不济。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">智慧线:</div>
					<p class="fs">
						1.位置:起于食指与中指之间，横跨掌心。
					</p>
					<p class="fs">
						2.意义:智慧线长而清晰者思维敏捷，短者思路直接，简单明了。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">感情线:</div>
					<p class="fs">
						1.位置:起于小指侧，向食指方向延伸。
					</p>
					<p class="fs">
						2.意义:感情线深长者感情丰富，情感细腻;短者感情较淡薄。
					</p>
					<div style="font-size: 1.1rem;margin-top: 2rem;">手指形状</div>
					<p class="fs">
						1.拇指:拇指长者通常意志坚定，具有领导才能。
					</p>
					<p class="fs">
						2.食指:食指长者多有自信心和掌控欲。
					</p>
					<p class="fs">
						3.中指:中指长者多具耐性和责任感。
					</p>
					<p class="fs">
						4.无名指:无名指长者多具艺术才华。
					</p>
					<p class="fs">
						5.小指:小指长者善于沟通，社交能力强。
					</p>
				
				<div class="shouxiang">
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">手掌特征</div>
					<div style="font-size: 1.1rem;margin-top: 2rem;">手掌厚薄:</div>
					<p class="fs" style="margin-bottom: 2rem;">
						1.厚实:通常代表身体健康，做事踏实。
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
						1.薄弱:可能体质较弱，性格敏感。
					</p>
					
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">手掌大小:</div>
					<p class="fs" style="margin-bottom: 2rem;">
						1.大掌:性格外向，注重行动。
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
						2.小掌:思维细腻，注重思考。
					</p>
				</div>
				
			
					<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">手相应用</div>
					
					<p class="fs" style="margin-bottom: 2rem;">
						手相常用于分析个人的性格特点、健康状况以及事业发展。不同的手相特征可以提示人们在特定阶段可能遇到的挑战和机遇。
					</p>
				
				
				
				<div class="itemtitle" style="margin-top: 1.5rem;">注意事项</div>
				<p class="itemtitle">
					面相和手相学作为传统文化的一部分，带有一定的象征性和解读性。在现代应用中，应结合个人实际情况和科学知识，理性看待其作用，不应过于依赖。
				</p>
				
			</div>
			
		</div>
		
		<div class="newboders">
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				结语
			</p>
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				国学命理学以丰富的哲学体系和历史积淀为基础，强调人与自然、宇宙的和谐关系。它既是一种文化传承，也是一种哲学思考方式，在现代社会中仍然有广泛的影响力和研究价值。
				需要注意的是，命理学是一种传统文化信仰，不同的人可能对其有不同的看法，具体应用时需结合科学理性进行分析。
			</p>
			
		</div>

	</div>
</template>

<script setup>

	import { ref } from 'vue';
	import { showToast } from 'vant';
	import {useRouter} from 'vue-router';
	const router = useRouter();
	const showPopover = ref(false);
	
	    // 通过 actions 属性来定义菜单选项
	    const actions = [
	      { text: 'English' },
	      { text: '中文简体' },
	      { text: '中文繁體' },
	    ];
	    const onSelect = (action) => {
			console.log(action.text);
			if(action.text == 'English') {
				router.push('/Homeen')
			}
			if(action.text == '中文简体') {
				router.push('/')
			}
			if(action.text == '中文繁體') {
				router.push('/Homezh')
			}
		};
</script>
<style lang="less" scoped>
	.fs {
		font-size: 1.1rem;
		line-height: 1.6;
		margin-top: 1rem;
	}

	.home {
		box-sizing: border-box;
		// min-height: 100vh;
		width: 100%;
		min-height: 150vh;
		background: url(../assets/homebj.png) no-repeat;
		background-size: cover;
		overflow-y: scroll;

		.title {
			text-align: center;
			font-family: '楷体';
			font-weight: 600;
			font-size: 1.5rem;
		}
			
		.fengshui {
			background: url(../assets/fengshui.png) no-repeat;
			background-size: 18rem 17rem;
			background-position: top right;
		}
		.shanshui {

			background: url(../assets/sshui.png) no-repeat;
			background-size: 100% ;
			background-position: center 3rem;
		}
          
		.shouxiang {
			background: url(../assets/shouxiang.png) no-repeat;
			background-size: 100% ;
			background-position: center 3rem;
		}
			
		.shouxiang {
			background: url(../assets/shouxiang.png) no-repeat;
			background-size: 70% 100%;
			background-position: top right;
		}
		
		.itembox,
		.itemboxs {
			margin-top: 2rem;
			// width: 100%;
			min-height: 40rem;
			background: url(../assets/newitembj.png) no-repeat;
			background-size: 100% 100%;
			// background-position: center center;
			background-position: .25rem 0rem;

			padding: 3rem 4rem;
			padding-bottom: 6rem;

			.itemtitle {
				margin: 0 auto;
				color: #af874b;
				font-size: 1.1rem;
				line-height: 1.6;
			}

			.boderbox {
				margin-top: 2rem;
				min-height: 20rem;
				border: 1px solid #e4d9ce;
				padding: 1rem;

				.bodertitle {
					display: inline-block;
					background: url(../assets/tixtbj.png) no-repeat;
					background-size: 100% 100%;
					padding: .5rem 1.5rem;
					color: #fff;
				}

				.baguabj {
					background: url(../assets/bgbg.png) no-repeat;
					background-size: 13rem 12rem;
					background-position: bottom right;
				}
			}
		}

		.itemboxs {
			background: url(../assets/itembj.png) no-repeat !important;
			background-size: 100% 100% !important;
			 
			
			
		}

		.newboder {
			width: 90%;
			min-height: 10rem;
			margin-left: 5%;
			background: url(../assets/newboder.png) no-repeat !important;
			background-size: 100% 100% !important;
			padding: 1.5rem 2rem;
			box-sizing: border-box;

		}
		.newboders {
			width: 90%;
			min-height: 10rem;
			margin-left: 5%;
			background: url(../assets/botttom.png) no-repeat !important;
			background-size: 100% 100% !important;
			padding: 1.5rem 2rem;
			box-sizing: border-box;
			margin-bottom: 9rem;
			margin-top: 3rem;
		
		}
	}
</style>